import React, { Component } from 'react';
import { t } from 'i18next';

class Footer extends Component {

    render() {
        const year = new Date().getFullYear()
        return (
            <footer className="footer-container">
                <span>
                    &copy; {year} - Reservi - 
                    <a href={t('footer:general-conditions.link')} target="_blank" rel="noopener noreferrer">{t('footer:general-conditions.text')}</a>
                </span>
            </footer>
        );
    }

}

export default Footer;
