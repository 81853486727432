import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/PageLoader';

import Base from './components/layout/Base';
import BasePage from './components/layout/BasePage';

import routes from './routing/routes';
import ErrorBoundary from './containers/Error/ErrorBoundary';
import componentLoader from './ComponentLoader';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;

// Authentication
const Login = lazy(() => componentLoader(() => import('./containers/Pages/Login')));
const Register = lazy(() => componentLoader(() => import('./containers/Pages/Register')));
const ForgotPassword = lazy(() => componentLoader(() => import('./containers/Pages/ForgotPassword')));
const ResetPassword = lazy(() => componentLoader(() => import('./containers/Pages/ResetPassword')));

const NotFound = lazy(() => componentLoader(() => import('./containers/Pages/NotFound')));
const ErrorPage = lazy(() => componentLoader(() => import('./containers/Pages/Error500')));

const AutoRequest = lazy(() => componentLoader(() => import('./containers/Pages/AutoRequest')));
const Appointment = lazy(() => componentLoader(() => import('./containers/Appointment/Appointment')));
const AppointmentType = lazy(() => componentLoader(() => import('./containers/AppointmentType/AppointmentType')));
const LastMinute = lazy(() => componentLoader(() => import('./containers/LastMinute/LastMinute')));
const Agenda = lazy(() => componentLoader(() => import('./containers/Agenda/Agenda')));
const Group = lazy(() => componentLoader(() => import('./containers/Group/Group')));
//const Contact = lazy(() => componentLoader(() => import('./containers/Contact/Contact')));

const TransactionExpired = lazy(() => componentLoader(() => import('./containers/Pages/TransactionExpired')));
const TransactionPaid = lazy(() => componentLoader(() => import('./containers/Pages/TransactionPaid')));
const TransactionError = lazy(() => componentLoader(() => import('./containers/Pages/TransactionError')));

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'
    const animationName = ''; // 'rag-fadeIn'

    if (
        location.pathname.includes('/auth/') === true ||
        location.pathname.includes('/a/p/') === true ||
        location.pathname.includes('/payment/') === true
    ) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <Route path={routes.e} component={waitFor(AutoRequest)} />

                        {/* See full project for reference */}
                        <Route exact path={routes.auth.forgotpassword} component={waitFor(ForgotPassword)} />
                        <Route exact path={routes.auth.resetpassword} component={waitFor(ResetPassword)} />
                        <Route exact path={routes.auth.login} component={waitFor(Login)} />
                        <Route exact path={routes.auth.register} component={waitFor(Register)} />

                        <Route path={routes.appointment.overview} component={waitFor(Appointment)} />

                        <Route path={routes.payment.expired} component={TransactionExpired} />
                        <Route path={routes.payment.paid} component={TransactionPaid} />
                        <Route path={routes.payment.error} component={TransactionError} />
                    </Switch>
                </Suspense>
            </BasePage>
        )
    } else {
        return (
            // Layout component wrapper
            <Base>
                <ErrorBoundary>
                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <Suspense fallback={<PageLoader />}>
                                <Switch location={location}>
                                    <Route exact path={routes.e} component={waitFor(AutoRequest)} />

                                    <Route path={routes.agenda.overview} component={waitFor(Agenda)} />

                                    <Route path={routes.group.overview} component={waitFor(Group)} />

                                    <Route path={routes.type.overview} component={waitFor(AppointmentType)} />
                                    <Route path={routes.lastminute.overview} component={waitFor(LastMinute)} />

                                    <Route path={routes.appointment.overview} component={waitFor(Appointment)} />
                                    {/*<Route path={routes.contact.overview} component={waitFor(Contact)} />*/}

                                    <Route path={routes.error} component={waitFor(ErrorPage)} />
                                    <Route path={routes.notfound} component={waitFor(NotFound)} />

                                    <Redirect to={routes.notfound} />
                                </Switch>
                            </Suspense>
                        </CSSTransition>
                    </TransitionGroup>
                </ErrorBoundary>
            </Base>
        )
    }
};

export default withRouter(Routes);