import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/**
 * Wrapper element for template content
 */
const ContentWrapper = props => (
    <div className={'content-wrapper bg-lightest ' + props.className}>
        {props.unwrap ?
            (<div className="unwrap">{props.children}</div>)
            :
            (props.children)
        }
    </div>
)

ContentWrapper.propTypes = {
    /** add element with 'unwrap' class to expand content area */
    unwrap: PropTypes.bool
}
ContentWrapper.defaultProps = {
    unwrap: false
}

const mapStateToProps = state => ({ client: state.client ? state.client.active : null });
export default connect(mapStateToProps)(ContentWrapper);
